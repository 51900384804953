.mealWrapper {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin: 1rem;

}
.meal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  
  .meal h3 {
    margin: 0 0 0.25rem 0;
  }
  
  .description {
    font-style: italic;
  }
  .picture {
    width: 100px;
    height: 100px;
  }
  
  .price {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #ad5502;
    font-size: 1.25rem;
  }
  /*
  .meal {
    display: flex;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}
.mealInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1rem;
  }
  
  .meal h3 {
    margin: 0 0 0.25rem 0;
  }

  
  .description {
    font-style: italic;
  }
  
  .price {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #ad5502;
    font-size: 1.25rem;
  }
  */